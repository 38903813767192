const colorArray = [
    `rgb (255,0,0)`,
    `rgb (0,255,0)`,
    `rgb (0,0,255)`,
    `rgb (255,255,0)`,
    `rgb (0,255,255)`,
    `rgb (255,0,255)`,
    `rgb (255,165,0)`,
    `rgb (0,128,0)`,
    `rgb (32,178,170)`,
    `rgb (75,0,130)`,
    `rgb (139,69,19)`,
    `rgb (255,105,180)`,
    `rgb (112,128,144)`,
    `rgb (0,0,0)`
]

export { colorArray }