import './Header.css';


function Header() {
    return (
      <div className="Header">
        <h1>Exam Analysis</h1>
      </div>
    );
  }
  
export default Header;